/* ==============
  Popover & Tooltips
===================*/

.popover-header {
  margin-top: 0;
}

.tooltip .tooltip-inner {
  padding: 4px 10px;
}