/* =============
   Alerts
============= */

.alert {
  position: relative;
  border: 0;
  .alert-link {
    font-weight: 500;
    text-decoration: underline;
  }
}

.alert-primary {
  color: $primary;
  background-color: rgba($primary, 0.15);
  .alert-link {
    color: darken($primary,10%);
  }
  hr {
    border-top-color: darken($primary,10%);
  }
}

.alert-success {
  color: $success;
  background-color: rgba($success, 0.15);
  .alert-link {
    color: darken($success,10%);
  }
  hr {
    border-top-color: darken($success,10%);
  }
}

.alert-danger {
  color: $danger;
  background-color: rgba($danger, 0.15);
  .alert-link {
    color: darken($danger,10%);
  }
  hr {
    border-top-color: darken($danger,10%);
  }
}

.alert-warning {
  color: $warning;
  background-color: rgba($warning, 0.15);
  .alert-link {
    color: darken($warning,10%);
  }
  hr {
    border-top-color: darken($warning, 10%);
  }
}

.alert-info {
  color: $info;
  background-color: rgba($info, 0.15);
  .alert-link {
    color: darken($info,10%);
  }
  hr {
    border-top-color: darken($info,10%);
  }
}

.alert-secondary {
  color: $secondary;
  background-color: rgba($secondary, 0.15);
  .alert-link {
    color: darken($secondary,10%);
  }
  hr {
    border-top-color: darken($secondary,10%);
  }
}

.alert-dark {
  color: $dark;
  background-color: rgba($dark, 0.15);
  .alert-link {
    color: darken($dark,10%);
  }
  hr {
    border-top-color: darken($dark,10%);
  }
}