
// pages


// LOG-IN PAGE


.home-btn {
    position: absolute;
    top: 22px;
    left: 22px;
    z-index: 1;
}

.accountbg {
    background: url("../images/bg-1.png");
    position: absolute;
    background-position: center center;
    height: 100%;
    width: 100%;
    top: 0;
  }
  
  .wrapper-page {
    margin: 7.5% auto;
    position: relative;
  }

  @media (max-width: 767px){
    .wrapper-page {
        width: 90%;
    }
  }


// COUNDOWN PAGE 

  .account-pages{
    margin: 10.5% auto;
    position: relative;
  }

  .comming-watch{
    div {
        display: inline-block;
        .card{
            margin: 0px 15px 15px 15px;
            .countdown-num
            {
                font-weight: 600;
                color: $primary;
            }
        }
        span {
            width: 150px;
            display: block;
            &:first-child {
                height: 60px;
                font-weight: 300;
                font-size: 3em;
                line-height: 48px;
            }
            &:last-child {
                padding-top: 14px;
                font-size: 0.9em;
            }
        }        
    }
  }

  .coming-soon-search-form {
    input {
        padding: 15px 20px;
        width: 100%;
        color: $gray-800;
        border: 2px solid $gray-200;
        outline: none !important;
        padding-right: 180px;
        padding-left: 30px;
        border-radius: 30px;
    }
    button {
        position: absolute;
        top: 7px;
        right: 8px;
        outline: none !important;
        border-radius: 30px;
        padding: 9px 30px;
    }
    form {
        position: relative;
        max-width: 600px;
        margin: 0px auto;
    }
}

// 

// 
// social.scss
//

.social-list-item {
  height: 32px;
  width: 32px;
  line-height: 28px;
  display: block;
  border: 2px solid $gray-500;
  border-radius: 50%;
  color: $gray-500;
}  


// ERROR PAGE

.error-bg{
  background: url("../images/bg-2.png");
  position: absolute;
  background-position: center center;
  height: 100%;
  width: 100%;
  top: 0;
}

.error-page { 
	text-transform: uppercase;
	background: repeating-linear-gradient(
  45deg,
  $info ,
  $info  10px,
  $primary  10px,
  $primary  20px
);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 100px;
	line-height: .7;
	position: relative;
}


// MAINTENANCE PAGE

.maintenance-img{
  img {
    max-width: 320px;
  }
}


