
@import url('https://fonts.googleapis.com/css?family=Nunito:600,700|Roboto:400,500,700&display=swap');

// Color variables
$white:     #ffffff;
$primary:   #23cbe0;
$success:   #20d4b6;
$info:      #0e86e7;
$warning:   #fdaf27;
$danger:    #fb4365;
$purple:    #745af1;
$blue:      #445ad8;
$muted:     #9ca8b3;
$secondary: #616f80;
$light:     #eff4fa;
$dark:      #242d3e;

// Gray Colors
$gray-100:                  #eff4fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #616f80;
$gray-700:                  #495057;
$gray-800:                  #354558;
$gray-900:                  #212529;

$bg-body: #f3f6fb;
$body-color: #354558;
$left-bar: #23cbe0;
$topbar-background: #ffffff;
$topbar-search-background: #eff4fa;
$footer-background: #ffffff;

// light leftbar

$light-leftbar:                     #ffffff;
$light-leftbar-menu-active-color:   #4090cb;
$topbar-background-dark:            #383c40;

$font-family: 'Nunito', sans-serif;
$font-family-secondary: 'Roboto', sans-serif;

$shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);

$base-font: 14px;

// Width variables
$width: 100%;

// Height variables
$height: 100%;

$leftbar-menu-color: #7e8cda;
$leftbar-menu-hover-color: #ffffff;
$leftbar-menu-active-color: #ffffff;