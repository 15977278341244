
  /* ==============
    Timeline
  ===================*/
  
  
  .cd-container {
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
  }
  .cd-container::after {
    content: '';
    display: table;
    clear: both;
  }
  #cd-timeline {
    margin-bottom: 2em;
    margin-top: 2em;
    padding: 2em 0;
    position: relative;
    &::before {
      background: rgba(12, 30, 54, 0.1);
      content: '';
      height: 100%;
      left: 18px;
      position: absolute;
      top: 0;
      width: 4px;
    }
  }
  @media only screen and (min-width: 1170px) {
    #cd-timeline {
      margin-bottom: 3em;
      margin-top: 3em;
      &::before {
        left: 50%;
        margin-left: -2px;
      }
    }
  }
  
  .cd-timeline-block {
    margin: 2em 0;
    position: relative;
    &:after {
      clear: both;
      content: "";
      display: table;
    }
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
  @media only screen and (min-width: 1170px) {
    .cd-timeline-block {
      margin: 4em 0;
    }
    .cd-timeline-block:first-child {
      margin-top: 0;
    }
    .cd-timeline-block:last-child {
      margin-bottom: 0;
    }
  }
  .cd-timeline-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    color: $white;
  }
  .cd-timeline-img.cd-success {
    background: $success;
  }
  .cd-timeline-img.cd-info {
    background: $info;
  }
  .cd-timeline-img.cd-blue {
    background: $blue;
  }
  .cd-timeline-img.cd-danger {
    background: $danger;
  }
  .cd-timeline-img.cd-primary {
    background: $primary;
  }
  .cd-timeline-img.cd-warning {
    background: $warning;
  }
  @media only screen and (min-width: 1170px) {
    .cd-timeline-img {
      width: 60px;
      height: 60px;
      line-height: 60px;
      left: 50%;
      margin-left: -30px;
      transform: translateZ(0);
    }
  }
  
  
  .cd-timeline-content {
    background: $white;
    border-radius: 0;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
    margin-left: 60px;
    padding: 1em;
    position: relative;
    img {
      display: block;
      width: 100%;
    }
    &:after {
      clear: both;
      content: "";
      display: table;
    }
    h2 {
      margin-top: 0;
    }
    p {
      color: $muted;
      margin: 10px 0px 10px 0px;
    }
    .cd-read-more {
      background:$white;
      border-radius: 0.25em;
      color:$white;
      display: inline-block;
      float: right;
      font-size: 14px;
      padding: .8em 1em;
    }
    .cd-date {
      display: inline-block;
      font-size: 14px;
    }
    h3 {
      font-size: 18px;
      margin: 0px;
    }
  }
  .no-touch .cd-timeline-content .cd-read-more:hover {
    background-color: #bac4cb;
  }
  .cd-timeline-content .cd-date {
    float: left;
    padding: .8em 0;
    opacity: .7;
  }
  .cd-timeline-content::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 12px solid transparent;
    border-right: 12px solid $light;
  }
  @media only screen and (min-width: 1170px) {
    .cd-timeline-content {
      margin-left: 0;
      padding: 1.6em;
      width: 45%;
      border-radius: 6px;
    }
    .cd-timeline-content::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: $white;
    }
    .cd-timeline-content .cd-read-more {
      float: left;
    }
    .cd-timeline-content .cd-date {
      position: absolute;
      width: 100%;
      left: 122%;
      top: 6px;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content {
      float: right;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
      top: 24px;
      left: auto;
      right: 100%;
      border-color: transparent;
      border-right-color: $white;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
      float: right;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
      left: auto;
      right: 122%;
      text-align: right;
    }
  }
  