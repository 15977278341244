/* ==============
  Helper Classes
===================*/


.w-30{
  max-width: 30px;
}
.w-xs {
  min-width: 80px;
}
.w-sm {
  min-width: 95px;
}
.w-md {
  min-width: 110px;
}
.w-lg {
  min-width: 140px;
}

.l-s-1{
  letter-spacing: 1px;
}
.l-s-2{
  letter-spacing: 2px;
}

.l-h-23{
  line-height: 23px;
}
.l-h-34 {
  line-height: 34px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-22{
  font-size: 22px;
}
.font-24{
  font-size: 24px;
}
.font-30{
  font-size: 30px;
}
.font-32{
  font-size: 32px;
}

.thumb-sm {
  height: 32px;
  width: 32px;
}
.thumb-md {
  height: 48px;
  width: 48px;
}
.thumb-lg {
  height: 88px;
  width: 88px;
}

.font-500{
  font-weight: 500;
}

.font-600{
  font-weight: 600;
}


.header-title {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 5px;
}

// rating 
.rating-symbol-foreground{
  top: 0px;
}

.sub-title {
  margin-bottom: 25px;
  color: $gray-500;
}

.bg-pattern{
  background-image: url("../images/bg-pattern.png");
  background-size: cover;
  background-position: bottom;
}